@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;600&display=swap');

// Variables
@import 'variables';

// Bootstrap
@import '~font-awesome/css/font-awesome';
@import '~slick-carousel/slick/slick';
@import '~slick-carousel/slick/slick-theme';
@import '~bootstrap/scss/bootstrap';

html {
	scroll-behavior: smooth;
	overflow-y: auto;
	overflow-x: hidden
}

body {
	font-family: 'Open Sans', sans-serif;
	font-size: 14px
}

.separator {
	height: 5px;
	background: red;
	margin: 40px 0;
	background-image: linear-gradient(75deg, #343a40, #6ada5c)
}

.search-form input.search-field {
	background-color: transparent;
	border: none;
	border-bottom: 1px solid #f3f3f3
}

::-webkit-scrollbar {
	width: 4px;
	height: 4px
}

::-webkit-scrollbar-track {
	background: #f1f1f1
}

::-webkit-scrollbar-thumb {
	background: #888
}

::-webkit-scrollbar-thumb:hover {
	background: #555
}

.navbar .navbar-nav.mx-auto.navbar .navbar-nav li.nav-item a.nav-link {
	color: #fff;
	margin: 0 10px;
	white-space: pre !important
}

.navbar-sticky {
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	z-index: 3
}

.bg-main-nav {
	background: #19242d
}

.bg-custom-black {
	background: #19242d
}

.bg-sec-nav {
	background: #252f3d
}

.nav-tabs li {
	border: #1f76b0
}

.nav-tabs {
	border-bottom: 0
}

.nav-tabs .nav-link {
	border: 0
}

.navbar ul li.active a.nav-link, .navbar ul li a.nav-link.active {
	position: relative;
	background-color: transparent;
	border: 0 !important
}

.navbar ul li.active a.nav-link:before, .navbar ul li a.nav-link.active:before {
	content: "";
	width: 36px;
	background: rgba(39, 217, 90, .5);
	position: absolute;
	left: -12px;
	top: 0;
	bottom: 0;
	height: 36px;
	border-radius: 50%
}

.master-header {
	position: relative
}

.header {
	height: 420px;
	background-repeat: no-repeat
}

.header-slider .item {
	height: 800px;
	background-position: top
}

.header-slider .item h1 {
	font-size: 42px;
	margin-top: 280px;
	z-index: 2
}

.overlay {
	background: rgba(0, 0, 0, .1)
}

.overlay, .overlay2 {
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0
}

.overlay2 {
	background: rgba(0, 0, 0, .7)
}

.arrows {
	position: absolute;
	bottom: 0;
	right: 0;
	z-index: 2
}

.arrows div {
	cursor: pointer;
	display: inline-block;
	background: #19242d;
	line-height: 3;
	padding: 0 15px
}

.arrows div.right, .sidebar .sidebar-header {
	background: #27d95a
}

.sidebar .sidebar-body {
	background: #252f3d
}

.sidebar .sidebar-body ul {
	margin: 0;
	padding: 0;
	list-style: none
}

.sidebar .sidebar-body ul li {
	width: 100%;
	display: block;
	float: left;
	position: relative
}

.sidebar .sidebar-body ul li a {
	display: inline-block;
	width: 100%;
	padding: 2px 0 2px 12px;
	line-height: 36px;
	color: #fff;
	font-size: 14px;
	border-bottom: 1px solid #3b424d;
	transition: color .2s linear, background .2s linear
}

.sidebar .sidebar-body ul li a:hover {
	text-decoration: none;
	background-color: #19242d
}

.sidebar .sidebar-body ul.submenu {
	width: 100%;
	position: static;
	padding-left: 0
}

.sidebar .sidebar-body .list-group > li > a > .fa {
	line-height: 36px;
	padding: 0 13.5px;
	background-color: #3b424d
}

.main-header {
	background: #f3f4f4;
	border-bottom: 3px solid #27d95a
}

.main-header a:first-of-type {
	border-left: 4px solid #fff
}

.main-header a {
	border-right: 4px solid #fff;
	text-decoration: none;
	color: #000;
	padding: 5px 14px
}

.main-header a.active {
	color: #fff;
	background: #27d95a
}

.category table tr td {
	font-size: 14px
}

.table td, .table th {
	padding: 10px
}

.cat-header {
	background: #f3f4f4
}

.borderless-table td, .borderless-table th {
	border: 0
}

.cp {
	cursor: pointer
}

.toggle-matches.collapsed img.toggle-icon {
	transition: .5s;
	transform: rotateX(180deg)
}

footer {
	background: #19242d
}

footer .links a {
	color: #fff;
	font-size: 14px;
	margin-right: 20px
}

.score .draw, .score .lose, .score .win {
	width: 15px;
	height: 10px;
	display: inline-block;
	transform: skewY(-20deg) rotate(20deg);
}

.score .win {
	background: green
}

.score .lose {
	background: red
}

.score .draw {
	background: orange
}

.bg-black {
	background: #000
}

.video-player {
	position: relative;
	padding: 52px 0
}

.video-player .content {
	padding: 45px;
	z-index: 2;
	position: relative
}

.video-player .player-footer {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 2
}

.video-player .player-footer .play-btn {
	line-height: 2
}

.video-player .play {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	height: 60px;
	z-index: 2
}

.odds-slider .slick-slide {
	margin: 0 10px
}

.odds-slider .slick-list {
	margin: 0 -10px
}

.odds-slider .item {
	outline: 0 !important
}

.country-collapse, .navbar-toggler {
	position: relative
}

.country-collapse i, .navbar-toggler i {
	position: absolute;
	top: 50%;
	right: 10px;
	transform: translateY(-50%);
	font-size: 14px
}

.country-collapse i {
	right: 25px
}

.badge.badge-dark, .badge.badge-success {
	cursor: pointer;
	border-radius: 0;
	font-weight: 700
}

.fixture-title {
	font-size: 1.5rem;
	font-weight: bold;
	text-align: center;
}

.fs12 {
	font-size: 12px
}

.timeline {
	overflow-x: auto
}

.timeline .stamp .lower, .timeline .stamp .upper {
	height: 100px;
	position: relative
}

.timeline .stamp .upper .content {
	position: absolute;
	bottom: 5px;
	left: 0;
	right: 0
}

.timeline .stamp {
	min-width: 150px
}

.timeline .stamp .timer {
	background: #fff;
	text-align: center;
	line-height: 2.3;
	height: 35px;
	width: 35px;
	border: 1px solid #ddd;
	z-index: 2;
	border-radius: 50%;
	margin: 0 auto;
	font-weight: 700
}

.timeline .stamp .timer:after {
	content: "";
	position: absolute;
	top: 50%;
	left: 0;
	right: 0;
	height: 1px;
	background: #ddd;
	z-index: -1
}

table.table.table-striped.teams td {
	font-size: 13px;
	padding: 6px
}

.p-link {
	color: inherit !important;
	text-decoration: none !important
}

.main-body tr td:first-of-type {
	width: 150px
}

.main-body tr td:nth-of-type(2), .main-body tr td:nth-of-type(4) {
	width: 220px
}

.main-body tr td:nth-of-type(3) {
	width: 45px;
	padding-left: 0;
	padding-right: 0
}

.card-score, .card-team-away, .card-team-home {
	display: inline-block;
	float: left
}

.card-score {
	width: 70px
}

.card-team-away, .card-team-home {
	width: calc(50% - 35px)
}

.breadcrumb {
	margin: 0 0 4px;
	padding: 0;
	line-height: 20px;
	font-size: 14px;
	display: flex;
	align-items: center;
	text-transform: capitalize;
	color: #000;
	background-color: transparent
}

.breadcrumb a, .breadcrumb a:hover, .breadcrumb span {
	color: #000;
	text-decoration: none
}

.page-link, .page-link:hover {
	color: #6ada5c
}

.page-item.active .page-link {
	color: #fff;
	background-color: #6ada5c;
	border-color: #6ada5c
}

/*# sourceMappingURL=app.css.map*/
.card.offers {
	font-size: 12px;
}

.prediction {
	padding: 5px;
	font-size: 12px;
	justify-content: space-around;
}

.prediction-card .card-header {
	color: #FFF;
	padding: .25rem 1.25rem;
	background-color: #3b424d;
}

.prediction-card .card-body, .prediction-card .card-footer {
	background-color: #252f3d;
}

.prediction-card .btn-bet {
	padding: 7px;
	white-space: nowrap;
}

.club-name {
	min-height: 3rem;
	font-size: 1.75rem;
}

.prediction-card .btn-bookmaker {
	max-width: 245px;
}

.bet-link .bet-name, .bet-link .bet-number {
	text-align: center;
	line-height: 3.25rem;
}

.home .max-height {
	overflow: auto;
	max-height: 800px;
}

.position-absolute-bottom {
	position: absolute !important;
	bottom: 0;
}

.bet-link .bet-name, .bet-link .bet-number, .bet-link img {
	height: 52px;
	width: 52px;
	display: inline-block;
	float: left
}

@media screen and (max-width: 1440px) and (min-width: 1024px) {
	.club-name {
		font-size: 1.25rem;
	}

	.bet-link .bet-name, .bet-link .bet-number {
		line-height: 2.5rem;
	}

	.bet-link .bet-name, .bet-link .bet-number, .bet-link img {
		width: 42px;
		height: 42px;
	}

	h2.display-3 {
		line-height: 1;
		font-size: 3.5rem;
	}
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
	.home .max-height {
		max-height: 250px;
	}

	.club-name {
		font-size: 1.5rem;
	}

	.bet-link .bet-name, .bet-link .bet-number, .bet-link img {
		width: 48px;
		height: 48px;
	}

	.bet-link .bet-name, .bet-link .bet-number {
		line-height: 3rem;
	}

	h2.display-3 {
		line-height: 1;
		font-size: 3.5rem;
	}
}

@media screen and (max-width: 768px) and (min-width: 600px) {
	.bet-link .bet-name, .bet-link .bet-number {
		line-height: 2.25rem;
	}

	.bet-link .bet-name, .bet-link .bet-number, .bet-link img {
		width: 36px;
		height: 36px;
	}
}

@media screen and (min-width: 601px) {
	.max-height2 {
		max-height: 585px;
		overflow: auto
	}

	.w-md-50 {
		width: 50% !important
	}

	.modal-dialog {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%) !important
	}
}

@media screen and (max-width: 600px) {
	.main-header a {
		text-decoration: none;
		color: #000;
		padding: 5px 14px;
		margin-bottom: 5px;
		display: inline-block
	}

	.header-slider .item {
		height: 600px
	}

	.modal-body img {
		height: 40px
	}

	.header-slider .item h1 {
		font-size: 28px;
		margin-top: 140px;
		z-index: 2
	}

	.header h2 {
		font-size: 1rem
	}

	.navbar ul li.active a.nav-link:before, .navbar ul li a.nav-link.active:before {
		width: 25px;
		height: 25px;
		top: 5px;
		left: -2px
	}

	.mb-menu {
		display: block !important
	}

	.mb-menu li {
		display: inline-block !important
	}

	.mb-menu li a.nav-link {
		margin: 0 3px !important
	}

	h3.main {
		font-size: 1.3rem
	}

	.odds-arrows .arrows {
		position: static;
		float: right
	}

	.fixture-title {
		text-align: left;
		font-size: 1.25rem;
	}

	.bet-link .bet-name, .bet-link .bet-number, .bet-link img {
		height: 32px;
		width: 32px;
		display: inline-block;
		float: left
	}

	.club-name {
		font-size: 1.5rem;
	}

	.score .draw, .score .lose, .score .win {
		width: 10px;
		height: 5px;
	}

	.bet-link .bet-name, .bet-link .bet-number {
		font-size: 12px;
		text-align: center;
		line-height: 1.9rem;
	}
}

@media screen and (max-width: 425px) {
	.club-name {
		font-size: 1rem;
	}
	.club-name-odds {
		font-size: 0.7rem
	}
	h2.display-3 {
		font-size: 1.9rem;
		margin-top: 1.5rem;
	}
}


.block-page-navigation {
	overflow:hidden;
	font-size:12px;
	color:#666;
	background:#f3f3f3;
	padding:10px 0
}
.block-page-navigation span {
	;
	font-weight:400;
	font-size:13px;
	color:#000;
	padding-right:25px;
	line-height:20px;
	vertical-align:top
}
.block-page-navigation span.title {
	text-transform:uppercase
}
.block-page-navigation span.dropdown-title {
	float:right;
	padding-right:15px;
	background:url("/assets/layout/svg/icon-sort-down-b.svg") no-repeat right;
	background-size:10px
}
.block-page-navigation ul {
	display:inline-block
}
.block-page-navigation ul li {
	display:inline-block;
	padding-right:18px;
	margin-right:8px;
	line-height:20px;
	padding-bottom:1px
}
.block-page-navigation ul li a {
	color:#666;
	padding-right:5px;

}
.block-page-navigation ul li.active {
	background:0 0;
	padding-right:0;
	color:#333
}
@media (min-width:992px) and (max-width:1199px) {
	.block-page-navigation span {
		padding-right:15px
	}
	.block-page-navigation ul li {
		padding-right:8px
	}
}
@media (min-width:768px) and (max-width:991px) {
	.block-page-navigation {
		padding-bottom:0
	}
	.block-page-navigation span {
		display:inline-block;
		margin-bottom:10px
	}
	.block-page-navigation ul {
		display:none
	}
	.block-page-navigation ul li {
		display:block;
		text-align:center;
		border-bottom:1px dotted #fff;
		line-height:38px
	}
}
@media (max-width:767px) {
	.block-page-navigation {
		padding-bottom:0
	}
	.block-page-navigation span {
		display:inline-block;
		margin-bottom:10px
	}
	.block-page-navigation ul {
		display:none
	}
	.block-page-navigation ul li {
		display:block;
		text-align:center;
		border-bottom:1px dotted #fff;
		line-height:38px
	}
}

.section-company-summary .box {
	background:#f3f3f3;
	padding:20px;
	overflow:hidden
}
.section-company-summary .box .author {
	width:20%;
	float:left;
	text-align:center;
	padding-right:20px
}
.section-company-summary .box .author img {
	margin-top:3px;
	border-radius:50%;
	margin-bottom:8px
}
.section-company-summary .box .author p {
	font-size:12px
}
.section-company-summary .box .text {
	width:80%;
	float:left
}
.section-company-summary .box .text p {
	;
	margin-bottom:4px;
	font-size:16px
}
@media (max-width:767px) {
	.section-company-summary .box .author {
		width:30%
	}
	.section-company-summary .box .text {
		width:70%
	}
}
.section-company-facts-box {
	position:relative;
	background:#f3f3f3;
	border:1px solid #dfdfdf;
	margin-bottom:50px
}
.section-company-facts-box.no-show-more .box {
	height:auto
}
.section-company-facts-box.no-show-more .expand {
	display:none!important
}
.section-company-facts-box.open .expand {
	background:0 0
}
.section-company-facts-box.open .expand .arrow {
	background-image:url("/img/icon-arrow-up-w.svg")
}
.section-company-facts-box .box {
	overflow:hidden;
	padding:20px;
	transition:height .4s;
	margin-bottom:20px
}
.section-company-facts-box .box .box-title {
	text-transform: uppercase;
	font-size: 16px;

	padding-bottom: 5px;
	border-bottom: 2px solid #27d95a;
	display: inline-block;
	margin-bottom: 20px;
	color: #282b2d
}
.section-company-facts-box .box table {
	display:block
}
.section-company-facts-box .box table tr {
	display:inline-block;
	width:50%;
	margin-bottom:15px;
	vertical-align:top;
	float:left;
}
.section-company-facts-box .box table tr td {
	display:block;
	font-size:16px;
	line-height:1.3em
}
.section-company-facts-box .expand {
	position:absolute;
	bottom:1px;
	width:100%;
	height:60px;
	z-index:99;
	background:-moz-linear-gradient(top,rgba(249,249,249,0) 0,#f9f9f9 75%);
	background:-webkit-linear-gradient(top,rgba(249,249,249,0) 0,#f9f9f9 75%);
	background:linear-gradient(to bottom,rgba(249,249,249,0) 0,#f9f9f9 75%)
}
.section-company-facts-box .expand .arrow {
	display:block;
	width:40px;
	height:40px;
	position:absolute;
	top:100%;
	left:50%;
	margin:-20px 0 0 -20px;
	border-radius:50%;
	background:#999 url("/assets/layout/svg/icon-arrow-down-w.svg") no-repeat center center;
	background-size:22px;
	cursor:pointer
}
@media (max-width:767px) {
	.section-company-facts-box .box .box-title {
		font-size:14px;
		padding-bottom:2px
	}
	.section-company-facts-box .box table tr td {
		font-size:14px
	}
}
.section-company-review .pros-cons {
	display:flex;
	padding-bottom:15px;
	border-bottom:1px solid #f3f3f3;
}
.section-company-review .pros-cons ul {
	width:50%
}
.section-company-review .pros-cons ul.pros li {
	background-image:url("./../img/icon-plus.svg")
}
.section-company-review .pros-cons ul.cons li {
	background-image:url("./../img/icon-minus.svg")
}
.section-company-review .pros-cons ul li {
	height: auto !important;
	font-size: 16px;
	line-height: 1.2em;
	padding-top: 5px;
	padding-bottom: 5px;
	background-size: 10px;
	background-position: left center;
	background-repeat: no-repeat;
	padding-left: 25px;

}
.section-company-review table.review {
	width:100%;
	margin-bottom:20px
}
.section-company-review table.review tr.odd {
	background:#f3f3f3
}
.section-company-review table.review tr td {
	padding:0 10px;

}
.section-company-review table.review tr td.title span {
	font-weight:400;
	text-transform:uppercase;
	font-size:14px;
	letter-spacing:.5px;
	line-height:38px
}
.section-company-review table.review tr td.rating {
	position:relative;
	text-align:right
}
.section-company-review table.review tr td.rating span {
	color:#ccc;
	font-size:26px;
	line-height:1em
}
.section-company-review table.review tr td.rating span.active {
	color:gold
}
.section-company-review table.review tr td.score {
	color:#999;
	font-size:12px
}
@media (min-width:768px) and (max-width:991px) {
	.section-company-review table.review tr td {
		padding:0 10px
	}
	.section-company-review table.review tr td.title span {
		font-size:14px
	}
	.section-company-review table.review tr td.rating span {
		font-size:18px
	}
}
@media (max-width:767px) {
	.section-company-review .pros-cons ul li {
		font-size:14px
	}
	.section-company-review table.review tr td {
		padding:0 5px
	}
	.section-company-review table.review tr td.title span {
		font-size:10px;
		line-height:28px
	}
	.section-company-review table.review tr td.rating span {
		color:#ccc;
		font-size:10px
	}
	.section-company-review table.review tr.score {
		font-size:10px
	}
}
.section-company-gallery .box {
	overflow:hidden
}
.section-company-gallery .box .image {
	position:relative;
	display:inline-block;
	margin:0 5px 5px 5px
}
.section-company-gallery .box a {
	position:absolute;
	z-index:999;
	top:0;
	display:block;
	width:100%;
	height:100%
}
.section-company-gallery .box a:hover {
	background:url("/assets/layout/icon-magnifier.png") no-repeat center center;
	background-color:rgba(0,0,0,.5);
	transition-duration:0s
}
.section-company-gallery .box .description p {
	margin-bottom:0;
	font-size:12px;
	line-height:1.2em
}
@media (max-width:767px) {
	.section-company-gallery .box .image {
		padding:0
	}
}
.page-title .line {
	height: 2px;
	width: 100%;
	background: #27d95a;
}
.section .page-title {
	margin-bottom: 20px;
}

.section {
	margin-bottom: 50px;
}


.section-company-facts-box .box table {
	display:block
}


.section-question-answer .box .item {
	background:#fbfbfb;
	padding:10px 20px;
	margin-bottom:4px
}
.section-question-answer .box .item.open {
	background:#f3f3f3
}
.section-question-answer .box .item.open h3 {
	background:url("./../img/icon-arrow-up-b.svg") no-repeat right;
	background-size:20px;
	text-decoration:underline
}
.section-question-answer .box .item.open .answer {
	display:block
}
.section-question-answer .box .item:hover {
	background-color:#efefef;
	cursor:pointer
}
.section-question-answer .box .item h3 {
	font-size:18px;
	background:url("./../img/icon-arrow-down-b.svg") no-repeat right;
	background-size:20px;
	padding:10px 30px 10px 0
}
.section-question-answer .box .item .answer {
	display:none
}
.section-question-answer .box .item .answer .text-default-format ol li,
.section-question-answer .box .item .answer .text-default-format p,
.section-question-answer .box .item .answer .text-default-format ul li {
	font-size:16px
}
.section-question-answer .box .item .answer .text-default-format ol,
.section-question-answer .box .item .answer .text-default-format ul {
	margin:4px
}

.cube {
	margin-bottom:50px
}
.cube .page-title {
	margin-bottom:3px
}
.cube .box {
	background:#f3f3f3;
	padding:15px
}
.cube .box.no-padding {
	padding-left:0;
	padding-right:0
}
.cube .box .text-default-format h3:first-child {
	margin-top:.5em
}
.cube .box .text-default-format ul li {
	font-size:16px
}
.cube .box .text-default-format ol li {
	font-size:16px
}
.cube .box .text-default-format p {
	font-size:16px
}
.cube-2 {
	margin-bottom:50px
}
.cube-2 .page-title {
	margin-bottom:15px
}
.cube-2 .box .text-default-format ul li {
	font-size:14px
}
.cube-2 .box .text-default-format p {
	font-size:14px
}
.cube-2 .read-more {
	text-align:right;
	margin-top:10px;

}
.cube-latest-news .item {
	padding-bottom:15px;
	border-bottom:1px solid #eee;
	margin-bottom:15px;
	overflow:hidden
}
.cube-latest-news .item img {
	float:left;
	margin-right:10px
}
.cube-latest-news .item h3 {
	font-size:20px;
	margin-bottom:3px;

}
.cube-latest-news .item .info span {
	font-size:11px;
	text-transform:uppercase
}
.cube-latest-news .item .info span.category {

	margin-right:12px
}
.cube-latest-news .item .info span.date {
	color:#999
}
@media (min-width:992px) and (max-width:1199px) {
	.cube-latest-news .item h3 {
		font-size:18px
	}
}
.cube-promotion-list .box {
	padding:15px 0
}
.cube-promotion-list .box ul li a {
	display:block;
	line-height:40px;
	border-bottom:1px dotted #fff;
	padding:0 20px
}
.cube-promotion-list .box ul li a:hover {
	background:#fff;
	text-decoration:none
}
.cube-promotion-list .box ul li a i {
	display: inline-block;
	margin-right: 10px;
	background: #27d95a;
	border-radius: 50%;
	width: 24px;
	height: 24px;
	color: #fff;
	text-align: center;
	line-height: 24px;

	font-style: normal;
	font-size: 13px
}
.cube-promotion-list .box ul li.active a {
	background:#fff;
	text-decoration:none
}
.cube-article-list .item {
	padding-bottom:15px;
	border-bottom:1px solid #eee;
	margin-bottom:15px;
	overflow:hidden
}
.cube-article-list .item img {
	float:left;
	margin-right:10px
}
.cube-article-list .item h3 {
	font-size:20px;
	margin-bottom:3px;

}
@media (min-width:992px) and (max-width:1199px) {
	.cube-article-list .item h3 {
		font-size:18px
	}
}
.cube-guide-list .item {
	position:relative;
	padding-bottom:15px;
	border-bottom:1px solid #eee;
	margin-bottom:15px
}
.cube-guide-list .item .overlay {
	position:absolute;
	z-index:9;
	background:rgba(255,255,255,.5);
	height:100%;
	width:100%
}
.cube-guide-list .item a {
	display:block;
	overflow:hidden
}
.cube-guide-list .item a .thumbnail {
	float:left;
	padding-right:10px;
	width:26%
}
.cube-guide-list .item a h3 {
	width:74%;
	float:left;
	font-size:18px;

}
.cube-guide-list .item a:hover h3 {
	text-decoration:underline
}
.cube-bonus-list {
	overflow:hidden
}
.cube-bonus-list .page-title.general-terms {
	margin-bottom:2px
}
.cube-bonus-list .logo {
	width:35px
}
.cube-bonus-list .bonus-text {
	width:calc(100% - 160px);
	padding:0 12px
}
.cube-bonus-list .button {
	width:125px
}
.cube-bonus-list .general-terms {
	position:relative;
	top:0
}
.cube-bonus-list .general-terms p {
	font-size:12px;
	padding:5px 0;
	text-align:center;
	background:#f7eff0;
	margin-bottom:4px;
	width:100%
}
.cube-bonus-list .box ol {
	margin:0;
	padding:0
}
.cube-bonus-list .box .item {
	background:#fbfbfb;
	padding:10px;
	border-bottom:1px solid #ccc;
	margin-bottom:3px;
	overflow:hidden;
	width:100%
}
.cube-bonus-list .box .item:hover {
	background:#fff
}
.cube-bonus-list .box .item .column {
	float:left
}
.cube-bonus-list .box .item .column.logo img {
	border-radius:4px;
	margin-right:7px
}
.cube-bonus-list .box .item .column.logo img:hover {
	opacity:.8
}
.cube-bonus-list .box .item .column.bonus-text h4 {
	display:inline-block;
	color:#ef4136;
	font-size:12px;
	text-transform:uppercase;
	vertical-align:middle;

	letter-spacing:1px
}
.cube-bonus-list .box .item .column.bonus-text p {
	font-size:16px;

	margin-bottom:0;
	color:#000;
	line-height:1.2em
}
.cube-bonus-list .box .item .column.bonus-text p i {
	font-style:normal;
	font-weight:400;
	font-size:12px;

}
.cube-bonus-list .box .item .column.button a {
	font-size:10px
}
.cube-bonus-list .box .item .terms {
	margin-top:5px
}
.cube-bonus-list .box .item .terms p {
	font-size:10px;
	line-height:14px;
	color:#b4b4b4;
	margin-bottom:0
}
.cube-bonus-list .terms {
	margin-top:10px;
	float:left
}
.cube-bonus-list .terms span {
	font-size:12px
}
.cube-bonus-list .read-more {
	float:right
}
@media (min-width:992px) and (max-width:1199px) {
	.cube-bonus-list .logo {
		width:35px
	}
	.cube-bonus-list .bonus-text {
		width:calc(100% - 140px);
		padding:0 10px
	}
	.cube-bonus-list .button {
		width:105px
	}
	.cube-bonus-list .box .item .column.bonus-text h4 {
		font-size:14px
	}
	.cube-bonus-list .box .item .column.button a {
		font-size:11px
	}
}
@media (min-width:768px) and (max-width:991px) {
	.cube-bonus-list .logo {
		width:35px
	}
	.cube-bonus-list .bonus-text {
		width:calc(100% - 200px);
		padding:0 13px
	}
	.cube-bonus-list .button {
		width:165px
	}
	.cube-bonus-list .box .item .column.bonus-text h4 {
		font-size:16px
	}
	.cube-bonus-list .box .item .column.button a {
		font-size:12px
	}
}
@media (max-width:767px) {
	.cube-bonus-list .logo {
		width:35px
	}
	.cube-bonus-list .bonus-text {
		width:calc(100% - 145px);
		padding:0 10px
	}
	.cube-bonus-list .button {
		width:110px
	}
	.cube-bonus-list .box .item .column.bonus-text h4 {
		font-size:14px
	}
	.cube-bonus-list .box .item .column.button a {
		font-size:11px
	}
}
.cube-body-plus .body-block {
	margin-bottom:15px
}
.cube-body-plus .body-block-facts-box {
	position:relative
}
.cube-body-plus .body-block-facts-box.no-show-more .box {
	height:auto
}
.cube-body-plus .body-block-facts-box.no-show-more .expand {
	display:none!important
}
.cube-body-plus .body-block-facts-box.open .expand {
	background:0 0
}
.cube-body-plus .body-block-facts-box.open .expand .arrow {
	background-image:url("/assets/layout/svg/icon-arrow-up-w.svg")
}
.cube-body-plus .body-block-facts-box .box {
	height:200px;
	overflow:hidden;
	transition:height .4s;
	padding:10px;
	margin-bottom:10px
}
.cube-body-plus .body-block-facts-box .box .box-title {
	text-transform: uppercase;
	font-size: 14px;

	padding-bottom: 5px;
	border-bottom: 2px solid #27d95a;
	display: inline-block;
	margin-bottom: 20px;
	color: #282b2d
}
.cube-body-plus .body-block-facts-box .box .text-default-format ol li,
.cube-body-plus .body-block-facts-box .box .text-default-format p,
.cube-body-plus .body-block-facts-box .box .text-default-format ul li {
	font-size:16px;
	color:#282b2d
}
.cube-body-plus .body-block-facts-box .box .author {
	width:30%;
	float:right;
	text-align:center;
	padding-left:20px
}
.cube-body-plus .body-block-facts-box .box .author img {
	margin-top:3px;
	border-radius:50%;
	margin-bottom:8px
}
.cube-body-plus .body-block-facts-box .box .author p {
	font-size:12px
}
.cube-body-plus .body-block-facts-box .expand {
	position:absolute;
	bottom:-25px;
	width:100%;
	height:110px;
	z-index:99;
	background:-moz-linear-gradient(top,rgba(249,249,249,0) 0,#f3f3f3 75%);
	background:-webkit-linear-gradient(top,rgba(249,249,249,0) 0,#f3f3f3 75%);
	background:linear-gradient(to bottom,rgba(249,249,249,0) 0,#f3f3f3 75%)
}
.cube-body-plus .body-block-facts-box .expand .arrow {
	display:block;
	width:40px;
	height:40px;
	position:absolute;
	top:100%;
	left:50%;
	margin:-20px 0 0 -20px;
	border-radius:50%;
	background:#999 url("/assets/layout/svg/icon-arrow-down-w.svg") no-repeat center center;
	background-size:22px
}
.cube-body-plus .terms {
	margin-top:10px
}
.cube-body-plus .terms p {
	font-size:10px!important;
	color:#999;
	margin-bottom:0
}
.cube-body-plus .body-block-button {
	text-align:center
}
.cube-body-plus .body-block-button a {
	padding:13px 33px;
	font-size:11px
}
.cube-featured-links .item {
	margin-bottom:10px;
	position:relative
}
.cube-featured-links .item a {
	display:flex;
	align-items:center;
	background:#eee;
	height:50px;
	padding:5px
}
.cube-featured-links .item a:hover {
	text-decoration:none;
	background: #27d95a
}
.cube-featured-links .item a:hover h4 {
	color:#fff
}
.cube-featured-links .item a:hover span.arrow {
	background:url("/assets/layout/svg/icon-arrow-right-thin-w.svg") no-repeat
}
.cube-featured-links .item a .logo {
	margin-right:0
}
.cube-featured-links .item a .logo img {
	width:40px;
	height:40px;
	border:2px solid #fff;
	border-radius:50%
}
.cube-featured-links .item a h4 {
	margin-left:10px;
	font-size:13px;
	text-transform:uppercase;
	letter-spacing:1px
}
.cube-featured-links .item a span.arrow {
	display:block;
	height:5px;
	width:15px;
	position:absolute;
	right:20px;
	background:url("/assets/layout/svg/icon-arrow-right-thin-b.svg") no-repeat
}
.cube-recommended-companies .item {
	margin-bottom:10px;
	position:relative
}
.cube-recommended-companies .item a {
	display:flex;
	align-items:center;
	background:#eee;
	height:50px;
	padding:5px
}
.cube-recommended-companies .item a:hover {
	text-decoration:none;
	background: #27d95a
}
.cube-recommended-companies .item a:hover h4 {
	color:#fff
}
.cube-recommended-companies .item a:hover span.arrow {
	background:url("/assets/layout/svg/icon-arrow-right-thin-w.svg") no-repeat
}
.cube-recommended-companies .item a .logo {
	margin-right:0
}
.cube-recommended-companies .item a .logo img {
	width:40px;
	height:40px;
	border:2px solid #fff;
	border-radius:50%
}
.cube-recommended-companies .item a h4 {
	margin-left:10px;
	font-size:13px;
	text-transform:uppercase;
	letter-spacing:1px
}
.cube-recommended-companies .item a span.arrow {
	display:block;
	height:5px;
	width:15px;
	position:absolute;
	right:20px;
	background:url("/assets/layout/svg/icon-arrow-right-thin-b.svg") no-repeat
}


.text-default-format ul {

	list-style: none outside url("./../img/icon-arrow-right.png");
	padding-left: 20px;
}

.text-default-format ul li {
	font-size: 18px;
	color: #555;
	line-height: 1.9em;
}

.cube .box .text-default-format ul li {
	font-size: 16px;
}

.page-title h1, .page-title h2, .page-title h3, .page-title h4, .page-title h5 {
	text-transform: uppercase;
	font-size: 16px;
	margin-bottom: 0;
	position: relative;
	z-index: 99;
	color: #fff;
	font-weight: 400;
	padding: 7px 10px 4px 10px;
	background: #27d95a;
	display: inline-block;
	letter-spacing: 1.5px;
}


.block-company-ranking.grey {
	background:#f3f3f3;
	padding:40px 0 20px 0
}
.block-company-ranking.grey .page-title h2 {
	background:#f3f3f3
}
.block-company-ranking.grey .item .item-open {
	background:#fff
}
.block-company-ranking.grey .item .item-left a {
	background:#fbfbfb
}
.block-company-ranking.grey .item .item-right .right-wrap {
	background:#fbfbfb
}
.block-company-ranking .item {
	margin-top: 30px;
}
.block-company-ranking .item .item-open {
	display:flex;
	border:1px solid #efefef;
	border-radius:4px;
	background:#fdfdfd
}
.block-company-ranking .item .item-left {
	width:20%;
	position:relative;
	overflow:hidden
}
.block-company-ranking .item .item-left a {
	display:block;
	text-align:center;
	background:#efefef;
	height:100%;
	width:100%;
	display:flex;
	align-items:center;
	justify-content:center;
	padding:30px 0;
	border-radius:4px
}
.block-company-ranking .item .item-left a .corner-tag {
	position:absolute;
	top:-39px;
	left:-39px;
	width:80px;
	height:80px;
	-moz-transform:rotate(-45deg);
	-webkit-transform:rotate(-45deg);
	-o-transform:rotate(-45deg);
	-ms-transform:rotate(-45deg);
	transform:rotate(-45deg);
	background-color:#fff;
	border-bottom:1px solid #eee
}
.block-company-ranking .item .item-left a .corner-tag img {
	width:30px;
	position:absolute;
	bottom:5px;
	left:50%;
	margin-left:-14px
}
.block-company-ranking .item .item-left a .corner-tag img.paypal-logo {
	width:26px;
	bottom:3px
}
.block-company-ranking .item .item-left a .corner-tag img.payment-logo {
	width:26px;
	bottom:3px
}
.block-company-ranking .item .item-left a .logo {
	align-self:center;
	margin-bottom:15px
}
.block-company-ranking .item .item-left a .logo img {
	margin:auto;
	max-width:120px;
	max-height:60px
}
.block-company-ranking .item .item-left a .logo span {
	margin-top:30px;
	display:block;
	text-transform:uppercase;
	color:#b1bec4;
	font-size:16px;
	letter-spacing:1px
}
.block-company-ranking .item .item-left a:hover {
	text-decoration:none
}
.block-company-ranking .item .item-left a .rating {
	margin-bottom:15px;
}
.block-company-ranking .item .item-left a .rating .stars span.star {
	display:inline-block;
	width:15px;
	height:15px;
	vertical-align:middle;
	background-image:url("./../img/star_grey.svg");
	background-size:15px;
	background-repeat:no-repeat;
	background-position:center center;
	position:relative
}
.block-company-ranking .item .item-left a .rating .stars span.star.active {
	background-image:url("./../img/star_golden.svg")
}
.block-company-ranking .item .item-left a .rating .score b {
	margin-left:10px;
	font-size:12px;
	font-weight:400;
	color:#fff
}
.block-company-ranking .item .item-left a .rating .score b i {
	font-style:normal;
	font-weight:700;
	font-size:18px;
	color:#fff
}
.block-company-ranking .item .item-left a span.read-more {
	font-size:13px;
	text-transform:uppercase;
	color:#fff
}
.block-company-ranking .item .item-right {
	width:80%;
	display:flex
}
.block-company-ranking .item .item-right .left-wrap {
	width:75%;
	padding:25px;
	position:relative
}
.block-company-ranking .item .item-right .left-wrap .top-row {
	padding-bottom:10px;
	margin-bottom:15px;
	border-bottom:1px solid #eee
}
.block-company-ranking .item .item-right .left-wrap .top-row h4 {
	text-transform:uppercase
}
.block-company-ranking .item .item-right .left-wrap .top-row .highlight-text {
	position:absolute;
	top:-7px;
	right:25px;
	display:inline-block;
	background:#2293d6;
	padding:3px 13px;
	-webkit-border-top-right-radius:8px;
	-webkit-border-bottom-right-radius:5px;
	-webkit-border-bottom-left-radius:5px;
	-moz-border-radius-topright:8px;
	-moz-border-radius-bottomright:5px;
	-moz-border-radius-bottomleft:5px;
	border-top-right-radius:8px;
	border-bottom-right-radius:5px;
	border-bottom-left-radius:5px
}
.block-company-ranking .item .item-right .left-wrap .top-row .highlight-text span {
	line-height:1em;
	color:#fff;
	font-size:12px;
	text-transform:uppercase;
	letter-spacing:.3px;

}
.block-company-ranking .item .item-right .left-wrap .top-row .highlight-text .corner {
	position:absolute;
	top:0;
	left:-9px;
	height:7px;
	width:9px;
	background:inherit;
	-webkit-border-top-left-radius:50%;
	-moz-border-radius-topleft:50%;
	border-top-left-radius:50%
}
.block-company-ranking .item .item-right .left-wrap .top-row .highlight-text .corner .filler {
	height:7px;
	width:9px;
	background:rgba(0,0,0,.3);
	-webkit-border-top-right-radius:50%;
	-webkit-border-top-left-radius:50%;
	-moz-border-radius-topright:50%;
	-moz-border-radius-topleft:50%;
	border-top-right-radius:50%;
	border-top-left-radius:50%
}
.block-company-ranking .item .item-right .left-wrap .bottom-row .pros-cons {
	display:flex
}
.block-company-ranking .item .item-right .left-wrap .bottom-row .pros-cons ul {
	width:50%
}
.block-company-ranking .item .item-right .left-wrap .bottom-row .pros-cons ul.pros li {
	background-image:url("./../img/icon-plus.svg")
}
.block-company-ranking .item .item-right .left-wrap .bottom-row .pros-cons ul.cons li {
	background-image:url("./../img/icon-minus.svg")
}
.block-company-ranking .item .item-right .left-wrap .bottom-row .pros-cons ul li {
	background-size:10px;
	background-position:left center;
	background-repeat:no-repeat;
	padding-left:25px;
	height:auto!important;
	font-size:14px;
	line-height:1.2em;
	padding-top:5px;
	padding-bottom:5px
}
.block-company-ranking .item .item-right .right-wrap {
	width:25%;
	padding:20px 20px 6px 20px;
	display:flex;
	align-items:center;
	justify-content:center;
	background:#efefef
}
.block-company-ranking .item .item-right .right-wrap .bonus {
	text-align:center;
	width:100%
}
.block-company-ranking .item .item-right .right-wrap .bonus .bonus-text {
	margin: 13px auto;
	font-size: 22px;

	color: #27d95a;
	line-height: 1.1em
}
.block-company-ranking .item .item-right .right-wrap .bonus .bonus-text i {
	font-style:normal;

	font-size:13px
}
.block-company-ranking .item .item-right .right-wrap .bonus h6 {
	margin:auto;
	text-transform:uppercase;

	font-size:13px;
	font-weight:700
}
.block-company-ranking .item .item-right .right-wrap .bonus a.text-link {
	font-size:11px;
	display:inline-block;
	margin-top:6px
}
.block-company-ranking .item .item-right .right-wrap .bonus.t-and-c .bonus-text {
	margin:10px auto
}
.block-company-ranking .item .item-right .right-wrap .bonus.t-and-c .terms {
	margin-top:6px
}
.block-company-ranking .item .item-right .right-wrap .bonus.t-and-c .terms p {
	font-size:9px;
	color:#b2b2b2;
	line-height:14px;
	margin-bottom:0
}
.block-company-ranking .item .item-hidden {
	display:none;
	border-left:2px solid #efefef;
	border-bottom:2px solid #efefef;
	border-right:2px solid #efefef;
	border-radius:3px;
	background:#fff
}
.block-company-ranking .item .item-hidden .item-hidden-flex {
	display:flex;
	padding:30px
}
.block-company-ranking .item .item-hidden .left-column {
	width:35%
}
.block-company-ranking .item .item-hidden .left-column .company-facts {
	margin-bottom:20px
}
.block-company-ranking .item .item-hidden .left-column .company-facts table {
	width:100%
}
.block-company-ranking .item .item-hidden .left-column .company-screenshots {
	overflow:hidden;
	display:flex
}
.block-company-ranking .item .item-hidden .left-column .company-screenshots .image {
	position:relative;
	height:100%
}
.block-company-ranking .item .item-hidden .left-column .company-screenshots .image.middle {
	margin:0 10px
}
.block-company-ranking .item .item-hidden .left-column .company-screenshots a {
	position:absolute;
	z-index:999;
	top:0;
	display:block;
	width:100%;
	height:100%
}
.block-company-ranking .item .item-hidden .left-column .company-screenshots a:hover {
	background:url("/assets/layout/icon-magnifier.png") no-repeat center center;
	background-color:rgba(0,0,0,.5);
	transition-duration:0s
}
.block-company-ranking .item .item-hidden .right-column {
	width:65%;
	padding-left:60px
}
.block-company-ranking .item .item-hidden .right-column .summary .top {
	overflow:hidden;
	border-bottom:1px solid #eee;
	margin-bottom:10px;
	padding-bottom:10px
}
.block-company-ranking .item .item-hidden .right-column .summary .top h4 {
	line-height:40px;
	float:left;
	font-size:16px;
	text-transform:uppercase
}
.block-company-ranking .item .item-hidden .right-column .summary .top .author {
	float:right
}
.block-company-ranking .item .item-hidden .right-column .summary .top .author img {
	width:40px;
	border-radius:50%;
	margin-left:10px
}
.block-company-ranking .item .show-more {
	margin:auto;
	height:35px;
	width:35px;
	text-align:center;
	cursor:pointer;
	background:#fff;
	border:1px solid #efefef;
	border-radius:50%;
	position:relative;
	top:-18px
}
.block-company-ranking .item .show-more img {
	width:50%;
	margin-top:7px
}
.block-company-ranking .item .show-more.active img {
	transform:rotate(180deg)
}
.block-company-ranking .read-more {
	text-align:right
}
@media (min-width:992px) and (max-width:1199px) {
	.block-company-ranking .item .item-right .right-wrap .bonus .bonus-text {
		font-size:18px
	}
}
@media (min-width:768px) and (max-width:991px) {
	.block-company-ranking .item .item-left {
		width:30%
	}
	.block-company-ranking .item .item-right {
		width:70%;
		display:block
	}
	.block-company-ranking .item .item-right .left-wrap {
		width:100%
	}
	.block-company-ranking .item .item-right .right-wrap {
		padding-top:0;
		width:100%
	}
	.block-company-ranking .item .item-right .right-wrap .bonus {
		border-top:1px solid #eee;
		padding:20px 0
	}
	.block-company-ranking .item .show-more {
		margin-left:calc(65% - 17px)
	}
	.block-company-ranking .item .show-more.active {
		margin:auto
	}
}
@media (max-width:767px) {
	.block-company-ranking .item .item-left {
		width:33.33%
	}
	.block-company-ranking .item .item-left a {
		position:relative;
		overflow:hidden;
		display:block;
		padding-top:60px
	}
	.block-company-ranking .item .item-left a .logo {
		width:80%;
		margin:auto;
		margin-bottom:10px
	}
	.block-company-ranking .item .item-left a .logo img {
		width:100%
	}
	.block-company-ranking .item .item-left a .rating {
		position:absolute;
		left:-10%;
		bottom:-40px;
		width:120%;
		height:150px;
		-webkit-border-top-left-radius:50%;
		-webkit-border-top-right-radius:50%;
		-moz-border-radius-topleft:50%;
		-moz-border-radius-topright:50%;
		border-top-left-radius:50%;
		border-top-right-radius:50%;
		background-color:#fff;
		flex-direction:column;
		align-items:center;
		border-top:1px solid #eee;
		padding-top:25px
	}
	.block-company-ranking .item .item-left a .rating .stars {
		order:2
	}
	.block-company-ranking .item .item-left a .rating .stars span.star {
		width:11px;
		height:11px;
		background-size:11px
	}
	.block-company-ranking .item .item-left a .rating .score b {
		font-size:16px;
		color:#555
	}
	.block-company-ranking .item .item-left a .rating .score b i {
		font-size:28px;
		color:#282b2d
	}
	.block-company-ranking .item .item-left a span.read-more {
		font-size:10px
	}
	.block-company-ranking .item .item-right {
		width:66.66%;
		display:block
	}
	.block-company-ranking .item .item-right .left-wrap {
		width:100%;
		padding:35px 20px 15px 20px
	}
	.block-company-ranking .item .item-right .left-wrap .top-row .highlight-text {
		left:14px;
		right:unset
	}
	.block-company-ranking .item .item-right .left-wrap .bottom-row .pros-cons {
		display:block
	}
	.block-company-ranking .item .item-right .left-wrap .bottom-row .pros-cons ul {
		width:100%
	}
	.block-company-ranking .item .item-right .left-wrap .bottom-row .pros-cons ul li {
		font-size:13px;
		padding-top:3px;
		padding-bottom:3px
	}
	.block-company-ranking .item .item-right .left-wrap .bottom-row .pros-cons ul.cons {
		margin-top:15px
	}
	.block-company-ranking .item .item-right .right-wrap {
		padding-top:0;
		width:100%
	}
	.block-company-ranking .item .item-right .right-wrap .bonus {
		border-top:1px solid #eee;
		padding:15px 0
	}
	.block-company-ranking .item .item-right .right-wrap .bonus .bonus-text {
		font-size:18px;
		margin:9px auto
	}
	.block-company-ranking .item .item-right .right-wrap .bonus .bonus-text i {
		font-size:12px
	}
	.block-company-ranking .item .item-hidden .item-hidden-flex {
		display:block
	}
	.block-company-ranking .item .item-hidden .item-hidden-flex .left-column {
		width:100%;
		padding-bottom:20px;
		margin-bottom:20px;
		border-bottom:1px solid #eee
	}
	.block-company-ranking .item .item-hidden .item-hidden-flex .right-column {
		width:100%;
		padding-left:0
	}
	.block-company-ranking .item .item-hidden .item-hidden-flex .right-column .summary .top h4 {
		font-size:14px
	}
	.block-company-ranking .item .item-hidden .item-hidden-flex .right-column .summary .top .author {
		font-size:12px
	}
	.block-company-ranking .item .show-more {
		margin-left:calc(66% - 17px)
	}
	.block-company-ranking .item .show-more.active {
		margin:auto
	}
}

/*ul {
	list-style: none outside none;
	margin: 0;
	padding: 0;
}*/
